import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

const Attention = props => null;
//  <Link to="verkauf">
//  <div className="container pt-8">
//   <div className="attention">
//   <h2> <strong> Nächster Abholtermin: </strong>
//        <p>{"Freitag, 20.12.2019"}</p>
//   </h2>
//   {/* <!--Es sind noch Fleischpakete verfügbar!-->*/}
//   </div>
//   </div>
//   </Link>
// );

export default Attention;
