import React from "react";
import { graphql, withPrefix, Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import Call from "../components/Call";
import { GatsbyImage } from "gatsby-plugin-image";
import Attention from "../components/Attention";

function findLatestDate(a) {
  return a.date_updated ? a.date_updated : a.date_created;
}

const Home = (props) => {
  const artikel = props.data.directus.Artikel;
  const relevant_artikel = artikel.sort((a, b) => 
    ((findLatestDate(a) < findLatestDate(b)) ? 1 : -1)
  );
  console.log('relevant sorted:', relevant_artikel);
  const imageWeide = props.data.startbild;
  return (
    <Layout bodyClass="page-home">
      <SEO
        title="Home"
        description="Wir sind ein Mutterkuhbetrieb in Reifersberg. Wir halten Rinder der Rasse Charolais auf unseren Weiden. Im Sommer haben wir auch Erdbeeren"
      />
      <div className="container">
        <GatsbyImage image={imageWeide.childImageSharp.gatsbyImageData} />
      </div>
      <Attention />

      <div className="intro pb-4">
        <div className="container">
          <h1>Jolhof</h1>
          <p>Charolaisfleisch Direktvermarktung</p>
        </div>
      </div>

      <div className="container pt-2">
        <Call button />
      </div>

      <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-dark mb-3">Rund um den Hof</h2>
          </div>
          {relevant_artikel.map((edge) => (
            <div
              key={edge.path}
              className="col-12 col-md-4 mb-1"
            >
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.path}>
                      {edge.title}
                    </Link>
                  </h2>
                  <p>{edge.kurzbeschreibung}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/hof">
              Mehr über den Hof
            </Link>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7"></div>
    </Layout>
  );
};

export const query = graphql`
  query {
    directus {
      Artikel(sort: "-date_updated", filter: { featured: { _eq: true } }) {
        status
        sort
        title
        kurzbeschreibung
        featured
        path
        date_updated_func {
          day
          year
          month
        }
        date_created_func {
          day
          year
          month
        }
        date_created
        date_updated
      }
    }
    startbild: file(relativePath: { eq: "erdbeeren_gepflueckt.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1400)
      }
    }
  }
`;

export default Home;
